import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Seo from "../components/seo";
import { AspectRatio } from "react-aspect-ratio";
import Helmet from "react-helmet";
import useWindowSize from "../hooks/useGatsbyWindowSize";
import ArrowLeft from "../images/arrow_left.svg";
import ArrowRight from "../images/arrow_right.svg";
import { useStaticQuery, graphql } from "gatsby";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import useSessionStorage from "../hooks/useSessionStorage";

const Outer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 20px;
  @media (max-width: 550px) {
    display: none;
  }
`;

const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 550px) {
    display: block;
    width: 100%;
    padding: 20px;
    min-height: 100vh;
  }
`;

const Container = styled.div`
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  @media (max-width: 820px) {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }

  opacity: 1;
  display: block;
  @media (max-width: 550px) {
    display: none;
  }

  &:not(:hover) {
    .blurb {
      opacity: 0;
    }
  }

  &:hover {
    .blurb-container {
      opacity: 1;
    }
    .container-not-hover {
      opacity: 0;
      .blurb {
        opacity: 0;
      }
    }
    .container-hover p {
      opacity: 1;
    }
  }
`;

const LeftRightContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  z-index: 0;
  display: block;
  @media (max-width: 850px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  width: 50vw;
  cursor: ${(props) =>
    props.seen
      ? `url(${ArrowLeft}) 0 16, pointer`
      : `url(${ArrowLeft}) 0 16, pointer`};
`;

const Right = styled.div`
  display: flex;
  height: 100vh;
  width: 50vw;
  position: relative;
  position: absolute;
  right: 0;
  cursor: ${(props) =>
    props.seen
      ? `url(${ArrowRight}) 51 16, pointer`
      : `url(${ArrowRight}) 51 16, pointer`};
`;

const Slide = styled.div`
  transition: all 1s ease-in-out !important;
  transition-delay: 0.5s !important;
  margin-right: 10px;
  margin-left: 10px;
  pointer-events: auto;
  position: relative;
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBzZiPVYMwEMa/doKOcBvoBs0GdgPZQDcoGzhCu4E6QdsJ1Alkg7KB3geXFvoHQwng773vPQgpd7lckisThDNTOdPc7sWe5arM9Kba2XU0aGyp2qs2qifVfcUB34dtiWptfVfW1hka/1alZqgNif2WzghuQFQfKEcu6EZqzrSKiuA4+lg4e+djSGexzs+Ij9i73V8dY0fgFE7JHg1TzCRcoX8Y5c2lB6L6QfckDIVOnOXHWvWC4XAoV1+NxnnqiY05g6lqofpE5G02gHezXTjhrGFotqoHf3MIywgwDWa1ixEotvOpOZBjHDLan+IfQCcYhbGm40DrYzYiRT4yEruRnBCUs5DTCW5UcwyPU335G+bDGMv07BBjQx+FzDUEZS7WcNY4VDTWuFI8vWKY49zhQhQ8MwTWgB2QEBt82FdtwUGykElDOieIv4FJGwc8C3Nkie44dKjiBeXSvTVPBOUqiJJnib2I8n+GmwyzP51nbqUIWPYThOPMwJ0Zy3CsQ6qfCfhpgOXiFoF1ShsnqnijfpT5iVOt+AUVF15WYVqPMgAAAABJRU5ErkJggg=="),
    auto;
`;

const Spacer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  height: 60px;
  cursor: default;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d9d9d9;
  cursor: pointer;
  &.active {
    background-color: #848484;
  }
`;

const Pagination = styled.div`
  display: none;
  @media (max-width: 820px) {
    display: flex;
    position: absolute;
    bottom: 30px;
    width: 100%;
    column-gap: 23px;
    justify-content: center;
  }
  span {
    font-size: 50px;
    color: #d9d9d9;
  }
`;

const SlideInner = styled.div`
  background-image: ${(props) => `url(${props.background_image})`};
  background-position: center center;
  background-size: cover;
`;

const Blurb = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  border: none;
  margin-top: 20px;
  margin-bottom: 40px;
  overflow-y: visible;
  display: block;
  min-height: 80px;
  cursor: pointer;
  pointer-events: none;
  transition: all 1s ease-in-out;
  white-space: pre-line;
  a {
    text-decoration: none;
    color: #000000;
  }
`;

const MobileSlide = styled.div`
  img {
    aspect-ratio: 325/440;
    overflow: hidden;
    object-fit: cover;
  }
`;

const Finishes = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const window_size = useWindowSize();
  const { width } = window_size;
  const flickRef = useRef(null);

  const [seen, setSeen] = useSessionStorage("finishes", false);

  const data = useStaticQuery(graphql`
    query {
      allSanityFinishes {
        edges {
          node {
            title
            images {
              description
              image {
                asset {
                  publicUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  const num_slides = data.allSanityFinishes.edges[0].node.images.length;

  const next_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.next();
      if (slideIndex === num_slides - 1) {
        setSlideIndex(0);
      } else {
        setSlideIndex(slideIndex + 1);
      }
    }
  };

  const prev_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.prev();
      if (slideIndex === 0) {
        setSlideIndex(num_slides - 1);
      } else {
        setSlideIndex(slideIndex - 1);
      }
    }
  };

  const handleScroll = (e) => {
    if (e < 0) {
      prev_slide(e);
    } else if (e > 0) {
      next_slide(e);
    }
    handleAnimate();
  };

  const handleAnimate = () => {
    if (!animating) {
      setAnimating(true);
      const id = setTimeout(() => {
        setAnimating(false);
      }, 1500);
      return () => clearTimeout(id);
    }
  };

  return (
    <>
      <Helmet bodyAttributes={{ class: "light noscroll" }} />
      <Spacer />
      <Seo title="Finishes" slug="finishes" />
      <Outer onWheel={(e) => handleScroll(e.deltaY)}>
        <LeftRightContainer seen={seen}>
          <Left seen={seen} onClick={(e) => prev_slide(e)}></Left>
          <Right seen={seen} onClick={(e) => next_slide(e)}></Right>
        </LeftRightContainer>

        <Container>
          <Flicking
            align="prev"
            ref={flickRef}
            inputType={["touch", "mouse"]}
            panelsPerView={width > 850 ? 4 : 3}
            interruptable={false}
            preventClickOnDrag={true}
            duration={1500}
            easing={(x) => 1 - Math.pow(1 - x, 3)}
            circular={true}
          >
            {data.allSanityFinishes.edges[0].node.images.map((s, index) => {
              return (
                <Slide key={index + 1}>
                  <SlideInner background_image={s.image.asset.publicUrl}>
                    <AspectRatio
                      ratio="325/440"
                      className="aspect"
                    ></AspectRatio>
                  </SlideInner>
                  <Blurb>{s.description}</Blurb>
                </Slide>
              );
            })}
          </Flicking>
        </Container>
        <Pagination>
          {data.allSanityFinishes.edges[0].node.images.map((s, index) => {
            // Calculate the adjusted index based on the total number of slides
            const adjustedIndex =
              index % data.allSanityFinishes.edges[0].node.images.length;

            return (
              <Dot
                key={index}
                className={index == slideIndex ? "active" : ""}
                onClick={(e) => {
                  if (
                    flickRef.current.animating === false &&
                    adjustedIndex !== slideIndex
                  ) {
                    flickRef.current.moveTo(adjustedIndex);
                  }
                }}
              />
            );
          })}
        </Pagination>
      </Outer>
      <ContainerMobile>
        {data.allSanityFinishes.edges[0].node.images.map((s, index) => {
          return (
            <MobileSlide key={index + 1}>
              <img src={s.image.asset.publicUrl} alt={s.title} />
              <Blurb>{s.description}</Blurb>
            </MobileSlide>
          );
        })}
      </ContainerMobile>
    </>
  );
};

export default Finishes;
